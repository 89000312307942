/* global headerSectionComponent */
/* eslint-disable no-unused-vars */
function titleCase(str) {
    try {
        return str.toLowerCase().split(' ').map(function (word) {
            if (word.length > 0)
                return word.replace(word[0], word[0].toUpperCase());
            return word;
        }).join(' ');
    }
    catch (e) {
        //console.log("Error en busqueda",e);
        return str;
    }
}

class CustomHeaderSectionComponent extends headerSectionComponent {

    static name() {
        return "headerSectionComponent";
    }

    static componentName() {
        return "headerSectionComponent";
    }

    data(){
        let superdata = super.data();
        superdata.shopping = false;
        superdata.fixedMenu = false;
        superdata.itemsPage = false;
        superdata.itemView = false;
        superdata.isMobile = $(window).width() <= 991;
        return superdata;
    }

    mounted() {
        let superCallback = super.mounted;
        return async function () {
            await superCallback();
            if(["CategorySEO","ItemList", "ItemViewSEO","ItemListSEO"].indexOf(this.$route.name) !== -1) {
                this.itemsPage = true;
                this.fixedMenu = true;
                this.itemView = true;
            }
            this.subscribeEvent("category-select", this.closeSubMenu);
            this.subscribeEvent('closeSandwichMenu', this.closeSandwichMenu);
        };
    }

    getMethods() {
        let superMethods = super.getMethods();
        superMethods.openSubMenu = this.openSubMenu;
        superMethods.closeSubMenu = this.closeSubMenu;
        return superMethods;
    }

    openSubMenu(){
        if(!this.shopping)
            $('.dropdown-toggle').dropdown('hide');
        this.shopping = !this.shopping;
    }

    closeSubMenu() {
        if(this.shopping){
            this.shopping = false;
            $('.dropdown-toggle').dropdown('hide');
            if (this.isMobile) {
                this.emitEvent('closeSandwichMenu');
            }
        }
    }

    getTemplate() {
        return `<div>
                    <div class="header">
                        <nav class="navbar navbar-expand-lg navbar-light" v-if="mustRender">
                            <router-link to="/home" class="nav-link brand-link" href="#">
                                <template v-if="hasBrandImg">
                                    <img class="brand-logo"  :src="logoUrl">
                                </template>
                                <template v-if="!hasBrandImg">
                                    <span class="brand-logo">{{menuTitle}}</span>
                                    <span class="app-name">{{menuSubTitle}}</span>
                                </template>
                            </router-link>
                            <ul class="navbar-nav d-sm-block d-lg-none ml-auto">
                                <li class="nav-item cart-icon-container" :title="tr(['Total Amount in Car $ {{$1}}', carAmount])">
                                    <router-link to="/badges" class="nav-link cart-icon pt-0">
                                        <i class="custom-icon cart"></i>
                                        <span class="cart-badge badge badge-light">
                                            {{metricNumber(parseInt(carItemBadge))}}
                                        </span>
                                    </router-link>
                                    <span class="total" v-if="$store.getters.getSetting.ShowTotalPrice">$ {{carAmount}}</span>
                                </li>
                            </ul>
                            <button class="navbar-toggler navbar-toggler-right align-self-center mt-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                                <span class="navbar-toggler-icon"><i class="icon fas fa-bars"></i></span>
                            </button>
                            <div class="container-fluid">
                                <div class="collapse navbar-collapse flex-column menu-ofisnack" id="navbarSupportedContent">
                                <div class="row w-100">
                                    <div class="menu-container col-12 col-lg-9">
                                        <div class="row h-100 align-items-end mt-2">
                                            <div class="col-12 align-self-end">
                                                <div class="search-wrapper form-inline " autocomplete="off"  @mouseenter="rapidMenu=true" >
                                                    <input list="searchinput" class="inputSEARCH search-input form-control mr-sm-2" type="search" v-model="filterValue" @change2="filter" @keydown="delayReload" @keydown.enter="filter" aria-label="Search" autocomplete="none" :placeholder="tr('ECOMSEARCHTEXT')">
                                                    <span class="search-icon" @click="filter"><i class="fas fa-search"></i></span>
                                                    <div class="header_datalist container pl-1 pl-sm-3 pr-1 pt-3" v-if="showRapidMenu" @mouseleave="rapidMenu=false">
                                                        <div class="categorias d-none" >
                                                        </div>
                                                        <!--h6 class="font-weight-bold my-3">Productos Sugeridos</h6-->
                                                        <div class="products-scroll products-scroll--alt" >
                                                            <template v-for="opt in optionList">
                                                                <div class="product-small product-small--alt py-2 mr-1">
                                                                    <div class="row">
                                                                        <div class="product__image col-3 col-md-2 px-2">
                                                                            <div class="item-detail-modal" >
                                                                                <itemSliderComponent :key="'slide'+opt.Code" :item="opt" :stockFlag="$store.getters.showStock" :container="false"  :zoom="false"></itemSliderComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div class="product__content col-9 col-md-10 px-2 align-self-center">
                                                                            <h6 class="col-12 pl-1">
                                                                                <router-link :to="'/view/'+encodeToURL(opt.Code)">{{opt.Name}}</router-link>
                                                                            </h6>
                                                                            <div class="col-12 pl-1">
                                                                                <div class="row align-items-center">
                                                                                    <div class="price col-12 col-sm-4 col-md-4" v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                                        $ {{$store.getters.showItemPrice(opt)}}
                                                                                    </div>
                                                                                    <div class="col-12 col-md-8 pl-0">
                                                                                        <div class="input-group add-to-cart-buttons">
                                                                                            <div class="input-group-prepend" @click="qtyDecrement(opt)">
                                                                                                <span class="input-group-text button" id="validationTooltipUsernamePrepend">
                                                                                                    <i class="icon fas fa-minus"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input :key="'th-input-'+opt.Code" type="text" class="form-control" :value="opt.carQty" :max="maxItemInCar(opt)" :disabled="maxItemInCar(opt)<1" @change="(event)=>updateCtyValue(event,opt)" @onfocus="autoResetBlur"  @focusout="(event)=>updateCtyValue(event,opt)" >
                                                                                            <div class="input-group-append" @click="qtyIncrement(opt)">
                                                                                                <span class="input-group-text button" id="validationTooltipUsernamePrepend">
                                                                                                    <i class="icon fas fa-plus"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div class="input-group-append" @click.stop="addToCar(opt)">
                                                                                                <span class="cart-button input-group-text button ml-2 p-2" id="validationTooltipUsernamePrepend">
                                                                                                    <i class="icon fas fa-cart-plus"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div class="col-12 text-center mt-2 mb-2" v-if="filterValue">
                                                            <div @click="filter" class="btn-link" >Ver todos los productos</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 align-self-end nav-links-container">
                                                <ul class="navbar-nav flex-column flex-lg-row my-2 row no-gutters">
                                                    <li class="nav-item col mt-2 mb-2 m-md-0 dropdown">
                                                        <a id="dropdownMenuAbout" data-toggle="dropdown" class="nav-link dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="closeSubMenu">Sobre Nosotros</a>

                                                        <div key="dropdown-menu-about" class="dropdown-menu dropdown-menu-header" aria-labelledby="dropdownMenuAbout">
                                                            <router-link class="dropdown-item" to="/about#historia">Historia</router-link>
                                                            <router-link class="dropdown-item" to="/about#mision">Misión</router-link>
                                                            <router-link class="dropdown-item" to="/about#proposito">Propósito</router-link>
                                                        </div>
                                                    </li>
                                                    <li class="nav-item col mb-2 m-md-0 dropdown">
                                                        <a id="dropdownMenuConcepto" data-toggle="dropdown" class="nav-link dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="closeSubMenu">Concepto</a>

                                                        <div key="dropdown-menu-concepto" class="dropdown-menu dropdown-menu-header" aria-labelledby="dropdownMenuConcepto">
                                                            <router-link class="dropdown-item" to="/comunidad">La Comunidad</router-link>
                                                            <router-link class="dropdown-item" to="/homeoffice">Home Office</router-link>
                                                            <router-link class="dropdown-item" to="/incompany">In Company</router-link>
                                                        </div>
                                                    </li>
                                                    <li v-if="!itemView" class="nav-item col mb-2 m-md-0" >
                                                        <a ref="#" class="nav-link" :class="{'active-menu': shopping}" @click.stop="openSubMenu">
                                                            Comprar
                                                        </a>
                                                        <template v-if="shopping">
                                                            <div key="dropdown-menu-categories" v-click-outside="closeSubMenu"> 
                                                                <subheaderSectionComponent></subheaderSectionComponent>
                                                            </div>
                                                        </template>
                                                    </li>
                                                    <li class="nav-item col mb-2 m-md-0 dropdown">
                                                        <a id="dropdownMenuRecipes" data-toggle="dropdown" class="nav-link dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="closeSubMenu">Recetas</a>

                                                        <div key="dropdown-menu-contact" class="dropdown-menu dropdown-menu-header" aria-labelledby="dropdownMenuRecipes">
                                                            <router-link class="dropdown-item" to="/recetas_trabajo">Soluciones para el trabajo</router-link>
                                                            <router-link class="dropdown-item" to="/recetas_hogar">Sencillas para el hogar</router-link>
                                                            <router-link class="dropdown-item" to="/recetas_snackear">Para snackear</router-link>
                                                        </div>
                                                    </li>
                                                    <li class="nav-item col mb-2 m-md-0 dropdown">
                                                        <a id="dropdownMenuContact" data-toggle="dropdown" class="nav-link dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="closeSubMenu">Contactanos</a>

                                                        <div key="dropdown-menu-contact" class="dropdown-menu dropdown-menu-header" aria-labelledby="dropdownMenuContact">
                                                            <router-link class="dropdown-item" to="/contact#sugerencias">Hacenos sugerencias</router-link>
                                                            <router-link class="dropdown-item" to="/contact#formaparte">Formá parte del equipo Ofisnack</router-link>
                                                            <router-link class="dropdown-item" to="/contact#proveedor">Quiero ser proveedor</router-link>
                                                            <router-link class="dropdown-item" to="/contact#reclamo">Envianos tu reclamo</router-link>
                                                            <router-link class="dropdown-item" to="/contactus">Formulario de Contacto</router-link>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 p-0">
                                        <ul class="navbar-nav flex-row ml-md-auto d-md-flex justify-content-start justify-content-lg-end">
                                            <li class="nav-item" v-if="session" :title="tr('Favorities')">
                                                <router-link to="/favorities" class="nav-link fav-icon position-relative">
                                                    <i class="custom-icon favourite"></i>
                                                </router-link>
                                                <div class="line"></div>
                                            </li>
                                            <li class="nav-item d-none d-lg-block cart-icon-container" :title="tr(['Total Amount in Car $ {{$1}}', carAmount])">
                                                <router-link to="/badges" class="nav-link cart-icon">
                                                    <i class="custom-icon cart"></i>
                                                    <span class="cart-badge badge badge-light">
                                                        {{metricNumber(parseInt(carItemBadge))}}
                                                    </span>
                                                </router-link>
                                                <div class="line"></div>
                                                <span class="total" v-if="$store.getters.getSetting.ShowTotalPrice">
                                                    <template v-if="Number.isNaN(Number.parseInt(carAmount))">
                                                        $ ...
                                                     </template>
                                                     <template v-else>
                                                        $ {{carAmount}}
                                                     </template>
                                                </span>
                                            </li>
                                            <li class="nav-item">
                                                <template  v-if="session">
                                                    <router-link key="profile-link" to="/profile" :title="tr('Profile')" class="nav-link">
                                                    <img key='avatar ' :src="avatarUrl" alt="" width="45" height="45" class="avatar img-fluid rounded-circle customer-image responsive-img">
                                                    </router-link>
                                                </template>
                                                <template v-else>
                                                    <router-link key="login-link" to="/login" :title="tr('Login')" class="nav-link">
                                                        <i class="custom-icon user"></i>
                                                    </router-link>
                                                </template>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </nav>
                        <template v-if="itemView" >
                            <subheaderSectionComponent class="items-page-subheader"></subheaderSectionComponent>
                        </template>
                    </div>
                </div>`;
    }
}

CustomHeaderSectionComponent.registerComponent();